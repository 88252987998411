import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, LineChart, Line  } from 'recharts';

import {ChartPivotRow, ResultSet} from '@cubejs-client/core';
import Utils from './Utils';
import CustomTooltip from './CustomTooltip';

function CustomChart(props: {
  colors: { [key: string]: string },
  data: ChartPivotRow[],
  resultSet: ResultSet<any>,
  stack: string,
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  type: 'bar' | 'line';

}) {
  const sortOrder = Object.entries(props.colors).map(([key, value]) => key);

  const sortSeries = (a, b) => {
    let positionA = sortOrder.findIndex((label) => label === a.yValues[0]);
    let positionB = sortOrder.findIndex((label) => label === b.yValues[0]);

    if (positionA < 0) {
      positionA = 99999;
    }
    if (positionB < 0) {
      positionB = 99999;
    }

    if (positionA >= positionB) {
      return 1;
    }
    return -1;
  };

  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const renderCharts = (resultSet) =>
    resultSet
      .seriesNames()
      .sort(sortSeries)
      .map((series, i) => (
        props.type === 'line' ?
        <Line
          key={series.key}
          dataKey={series.key}
          name={series.yValues[0]}
          stroke={props.colors[series.yValues[0]]}
          dot={false}
        /> : <Bar
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.yValues[0]}
          fill={props.colors[series.yValues[0]]}
          />
      ));

  return <ResponsiveContainer width="100%" height={600}>
    {props.type === 'line' ?
    <LineChart data={props.data} stackOffset={(props.stack as 'expand') ?? 'none'}>
      <Legend layout="horizontal" verticalAlign="top" align="center" />
      <XAxis dataKey="x" tickFormatter={formatXAxis} />
      <YAxis />
      <CartesianGrid />

      {renderCharts(props.resultSet)}

      <Tooltip content={<CustomTooltip/>}/>
    </LineChart> : <BarChart data={props.data} stackOffset={(props.stack as 'expand') ?? 'none'}>
      <Legend layout="horizontal" verticalAlign="top" align="center" />
      <XAxis dataKey="x" tickFormatter={formatXAxis} />
      <YAxis />
      <CartesianGrid />

      {renderCharts(props.resultSet)}

      <Tooltip content={<CustomTooltip/>}/>
    </BarChart>}
  </ResponsiveContainer>;

}

export default CustomChart;
