import React from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import 'antd/dist/antd.css';
import { Table } from 'antd';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';

const cubejsApi = Utils.cubejsApi();

function WeeklyRepeatersCVR(props: {
  shopIds: string[],
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  options: { [key: string]: any };
  title: string;
  excludeZeroYen: boolean;
  includeUnpaid: boolean;
}) {
  const filters: Array<any> = [];

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      filters.push({
        member: 'Orders.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      filters.push({
        member: 'Orders.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.salesChannels) {
    filters.push({
      member: 'Orders.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
  }

  if (props.salesMethods) {
    const salesMethods: Array<string> = [];
    if (props.salesMethods.includes('app')) {
      salesMethods.push('appOrder');
    }
    if (props.salesMethods.includes('self_checkout')) {
      salesMethods.push('selfCheckoutOrder');
    }
    if (props.salesMethods.includes('qr_bento')) {
      salesMethods.push('qrBento');
    }
    if (salesMethods.length> 0){
      filters.push({
        member: 'Orders.salesMethod',
        operator: 'equals',
        values: salesMethods,
      });
    }
  }

  const segments: Array<string> = [];

  if (props.options && (props.options.excludeZeroYen || props.excludeZeroYen)) {
    segments.push('Orders.excludeZeroYen');
  }

  if (!props.includeUnpaid) {
    segments.push('Orders.paidOrder');
  }

  const renderFunction = (text, record, index) => {
    const percentage = parseFloat(text) * 100;
    return `${percentage.toFixed(2)}%`;
  };

  const renderCharts = (resultSet) => {
    const columnMap = {
      'WeeklyRepeatersCVR.week': {
        title: '週',
      },
      'WeeklyRepeatersCVR.new_customers': {
        title: '新規',
        align: 'right',
      },
      'WeeklyRepeatersCVR.w0_customers': {
        title: '0週目CV',
        align: 'right',
      },
      'WeeklyRepeatersCVR.w0_customers_cvr': {
        title: '0週目CVR',
        align: 'right',
        render: renderFunction,
      },
      'WeeklyRepeatersCVR.w1_customers': {
        title: '1週目CV',
        align: 'right',
      },
      'WeeklyRepeatersCVR.w1_customers_cvr': {
        title: '1週目CVR',
        align: 'right',
        render: renderFunction,
      },
      'WeeklyRepeatersCVR.w2_customers': {
        align: 'right',
        title: '2週目CV',
      },
      'WeeklyRepeatersCVR.w2_customers_cvr': {
        title: '2週目CVR',
        align: 'right',
        render: renderFunction,
      },
      'WeeklyRepeatersCVR.w3_customers': {
        align: 'right',
        title: '3週目CV',
      },
      'WeeklyRepeatersCVR.w3_customers_cvr': {
        title: '3週目CVR',
        align: 'right',
        render: renderFunction,
      },
      'WeeklyRepeatersCVR.w4_customers': {
        align: 'right',
        title: '4週目CV',
      },
      'WeeklyRepeatersCVR.w4_customers_cvr': {
        title: '4週目CVR',
        align: 'right',
        render: renderFunction,
      },
    };

    return (
      <>
        <Table
          pagination={{ position: ['topLeft'] }}
          columns={resultSet.tableColumns().map((col) => ({ ...col, ...columnMap[col.key] }))}
          dataSource={resultSet.tablePivot()}
        />
        <CsvDownloader title={props.title} resultSet={resultSet} />
      </>
    );
  };

  return (
    <QueryRenderer
      query={{
        measures: [
          'WeeklyRepeatersCVR.new_customers',
          'WeeklyRepeatersCVR.w0_customers',
          'WeeklyRepeatersCVR.w0_customers_cvr',
          'WeeklyRepeatersCVR.w1_customers',
          'WeeklyRepeatersCVR.w1_customers_cvr',
          'WeeklyRepeatersCVR.w2_customers',
          'WeeklyRepeatersCVR.w2_customers_cvr',
          'WeeklyRepeatersCVR.w3_customers',
          'WeeklyRepeatersCVR.w3_customers_cvr',
          'WeeklyRepeatersCVR.w4_customers',
          'WeeklyRepeatersCVR.w4_customers_cvr',
        ],
        timeDimensions: [],
        order: {
          'WeeklyRepeatersCVR.week': 'desc',
        },
        dimensions: ['WeeklyRepeatersCVR.week'],
        filters,
        segments,
      }}
      cubejsApi={cubejsApi}
      render={({ resultSet }) => {
        if (!resultSet) {
          return 'Loading...';
        }
        return <>{renderCharts(resultSet)}</>;
      }}
    />
  );
}

export default WeeklyRepeatersCVR;
