import cubejs from '@cubejs-client/core';
import dayjs from './dayjs';

const jwt = require('jsonwebtoken');

export default class Utils {
  static cubejsApi() {
    const signedToken = jwt.sign(
      {},
      'b7d5c6c2733f0c6bed0eaae0e4f28249f78c80d1f735841bf092c2ed3e542c5aaa667c75f4cade9b56a902e1505c1d9583a985422da88317328e1fea9d426353',
      { expiresIn: '30d' },
    );

    const host = process.env.REACT_APP_cubejsApiHost;
    const api = cubejs(signedToken, { apiUrl: `${host}/cubejs-api/v1` });

    return api;
  }

  static formatXAxisLabel(value: string, period: 'day' | 'week' | 'month' | 'quarter' | 'fyear') {
    const day = dayjs(value);

    switch (period) {
      case 'day':
        return day.format('M/D');
      case 'week':
        return day.format('YYYY/M');
      case 'month':
        return day.format('YYYY/M');
      case 'quarter':
        return day.format('YYYY/M');
      case 'fyear':
        return day.format('YYYY');
      default:
        return value;
    }
  }
}
