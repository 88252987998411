import React from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import { Filter } from '@cubejs-client/core';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';

const cubejsApi = Utils.cubejsApi();

function Sales(props: {
  shopIds: string[],
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  options: { [key: string]: any };
  title: string;
  ravedCustomers: boolean;
  limitLastNDaysActiveShops: number|null;
  excludeZeroYen: boolean;
  includeUnpaid: boolean;
}) {
  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const filters: Array<Filter> = [
    {
      member: 'Orders.status',
      operator: 'notEquals',
      values: ['canceled'],
    },
  ];

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      filters.push({
        member: 'Orders.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      filters.push({
        member: 'Orders.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.salesChannels) {
    filters.push({
      member: 'Orders.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
  }

  if (props.salesMethods) {
    const salesMethods: Array<string> = [];
    if (props.salesMethods.includes('app')) {
      salesMethods.push('appOrder');
    }
    if (props.salesMethods.includes('self_checkout')) {
      salesMethods.push('selfCheckoutOrder');
    }
    if (props.salesMethods.includes('qr_bento')) {
      salesMethods.push('qrBento');
    }
    if (salesMethods.length> 0){
      filters.push({
        member: 'Orders.salesMethod',
        operator: 'equals',
        values: salesMethods,
      });
    }
  }

  const segments: Array<string> = [];

  if (props.options && (props.options.excludeZeroYen || props.excludeZeroYen)) {
    segments.push('Orders.excludeZeroYen');
  }

  if (!props.includeUnpaid) {
    segments.push('Orders.paidOrder');
  }

  if (props.options && props.options.repeaterOnly) {
    segments.push('Orders.repeaterOrder');
  }

  if (props.ravedCustomers) {
    segments.push('Orders.ravedCustomers');
  }

  if (props.limitLastNDaysActiveShops) {
    filters.push({
      member: 'Slots.time',
      operator: 'afterDate',
      values: [dayjs().tz('Asia/Tokyo').subtract(props.limitLastNDaysActiveShops - 1, 'days').startOf('day').format('YYYY-MM-DD')]
    });
  }

  const renderCharts = (resultSet) => {
    const charts: Array<React.ReactNode> = [];
    const series = resultSet.seriesNames();

    if (series.length === 0) {
      return <></>;
    }

    charts.push(
      <Bar key={series[0].key} dataKey={series[0].key} name="クーポン前売り上げ" fill="#4285F4" yAxisId="left" />,
    );

    charts.push(
      <Bar key={series[1].key} dataKey={series[1].key} name="クーポン後売り上げ" fill="#DB4437" yAxisId="left" />,
    );

    charts.push(
      <Line
        key={series[2].key}
        dataKey={series[2].key}
        name="クーポン前一杯単価"
        stroke="#4285F4"
        yAxisId="right"
        dot={false}
      />,
    );

    charts.push(
      <Line
        key={series[3].key}
        dataKey={series[3].key}
        name="クーポン後一杯単価"
        stroke="#DB4437"
        yAxisId="right"
        dot={false}
      />,
    );

    return charts;
  };

  return (
    <>
      <QueryRenderer
        query={{
          timeDimensions: [
            {
              dimension: 'Orders.cookedAt',
              granularity: props.period as any,
              dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
            },
          ],
          measures: [
            'Orders.salesBeforeCoupon',
            'Orders.salesAfterCoupon',
            'Orders.unitPriceBeforeCoupon',
            'Orders.unitPriceAfterCoupon',
          ],
          filters,
          segments,
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: false });
          return (
            <>
              <ResponsiveContainer width="100%" height={600}>
                <ComposedChart
                  data={data}
                >
                  <Legend layout="horizontal" verticalAlign="top" align="center" />
                  <XAxis dataKey="x" tickFormatter={formatXAxis} tick={{}} />
                  <YAxis yAxisId="left" width={100} />
                  <YAxis yAxisId="right" orientation="right" />
                  <CartesianGrid />

                  {renderCharts(resultSet)}

                  <Tooltip />
                </ComposedChart>
              </ResponsiveContainer>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default Sales;
