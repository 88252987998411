import React from 'react';
import { TooltipProps  } from 'recharts';

function CustomTooltip({ active, payload, label }: TooltipProps<number, string>, ignores?:Array<string>): JSX.Element  {
    if (active) {
      if (payload) {
        const filterdPayload = payload.filter((data) => data.type !== 'none' && (data.value && data.value > 0));
        const total = filterdPayload.map((data) => data.value || 0).reduce((sum, a) => sum+a,0);

        return (
          <div className="container bg-white">
            <h6>
            {label.replace('T00:00:00.000', '')}
            </h6>

            合計: {Math.round(total)}
              <table className="table table-borderless table-sm">
                {filterdPayload.reverse().map((data) => (
                  <tbody>
                    <tr className="p-0">
                      <td>
                        <span style={{color: data.color}}>
                          {data.name}
                        </span>
                      </td>
                      <td>
                        <span style={{color: data.color}}>
                          {data.value && Math.round(data.value)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
          </div>
        );
      }
    }

    return <></>;
};

export default CustomTooltip;