import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import { Filter } from '@cubejs-client/core';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';

const cubejsApi = Utils.cubejsApi();

function RepeaterOrders(props: {
  shopIds: string[],
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  stack: string;
  options: { [key: string]: any };
  title: string;
  excludeZeroYen: boolean;
  includeUnpaid: boolean;
}) {
  const colors = {
    '7日リピーター': '#ff0000',
    '14日リピーター': '#ff8000',
    '30日リピーター': '#ffbf00',
    '60日リピーター': '#80ff00',
    '90日リピーター': '#00ffff',
    '180日リピーター': '#0000ff',
    '再来店(リピーター以外)': '#4d0000',
    アプリ新規: '#ff00ff',
    セルフレジ: '#b05a75',
    QR弁当: '#E75480',
  };

  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const filters: Array<Filter> = [
    {
      member: 'Orders.status',
      operator: 'notEquals',
      values: ['canceled'],
    },
  ];

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      filters.push({
        member: 'Orders.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      filters.push({
        member: 'Orders.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.salesChannels) {
    filters.push({
      member: 'Orders.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
  }

  if (props.salesMethods) {
    const salesMethods: Array<string> = [];
    if (props.salesMethods.includes('app')) {
      salesMethods.push('appOrder');
    }
    if (props.salesMethods.includes('self_checkout')) {
      salesMethods.push('selfCheckoutOrder');
    }
    if (props.salesMethods.includes('qr_bento')) {
      salesMethods.push('qrBento');
    }
    if (salesMethods.length> 0){
      filters.push({
        member: 'Orders.salesMethod',
        operator: 'equals',
        values: salesMethods,
      });
    }
  }

  const segments: Array<string> = [];

  if (props.options && (props.options.excludeZeroYen || props.excludeZeroYen)) {
    segments.push('Orders.excludeZeroYen');
  }

  if (!props.includeUnpaid) {
    segments.push('Orders.paidOrder');
  }

  const sortOrder = [
    '7日リピーター',
    '14日リピーター',
    '30日リピーター',
    '60日リピーター',
    '90日リピーター',
    '180日リピーター',
    '再来店(リピーター以外)',
    'アプリ新規',
    'セルフレジ',
    'QR弁当',
  ];
  const sortSeries = (a, b) => {
    let positionA = sortOrder.findIndex((label) => label === a.yValues[0]);
    let positionB = sortOrder.findIndex((label) => label === b.yValues[0]);

    if (positionA < 0) {
      positionA = 99999;
    }
    if (positionB < 0) {
      positionB = 99999;
    }

    if (positionA >= positionB) {
      return 1;
    }
    return -1;
  };

  const renderCharts = (resultSet) => {
    const seriesNames = resultSet.seriesNames();
    const charts = seriesNames
      .slice(0, seriesNames.length - 2)
      .sort(sortSeries)
      .map((series, i) => (
        <Bar
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.yValues[0]}
          fill={colors[series.yValues[0]]}
        />
      ));

    const shopSeries = seriesNames[seriesNames.length - 2];

    if (shopSeries) {
      charts.push(
        <Line
          tooltipType='none'
          key={shopSeries.key}
          dataKey={shopSeries.key}
          name="親店舗数"
          stroke="#3e4444"
          strokeWidth={2}
          dot={false}
        />,
      );
    }

    return charts;
  };

  return (
    <>
      <QueryRenderer
        query={[
          {
            timeDimensions: [
              {
                dimension: 'Orders.cookedAt',
                granularity: props.period as any,
                dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
              },
            ],
            measures: ['Orders.numberOfCurries'],
            filters,
            segments,
            dimensions: ['Orders.repeaterSegment'],
          },
          {
            timeDimensions: [
              {
                dimension: 'Orders.cookedAt',
                granularity: props.period as any,
                dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
              },
            ],
            measures: ['Orders.numberOfUniqueKitchenShops', 'Orders.numberOfUniqueKitchenShopsDays'],
            filters,
            segments,
          },
        ]}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }

          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: false });

          const finalData = data.map((d) => {
            const numberOfShops = d['Orders.numberOfUniqueKitchenShops'] / d['Orders.numberOfUniqueKitchenShopsDays'];

            const v = {};
            for (const key of Object.keys(d)) {
              if (key === 'x' || key === 'xValues' ) {
                v[key] = d[key];
              } else if (key === 'Orders.numberOfUniqueKitchenShops') {
                v[key] = numberOfShops;
              } else {
                v[key] = d[key] / numberOfShops;
              }
            }

            return v;
          });

          return (
            <>
              <ResponsiveContainer width="100%" height={600}>
                <ComposedChart data={finalData} stackOffset={(props.stack as 'expand') ?? 'none'}>
                  <Legend layout="horizontal" verticalAlign="top" align="center" />
                  <XAxis dataKey="x" tickFormatter={formatXAxis} />
                  <YAxis />
                  <CartesianGrid />

                  {renderCharts(resultSet)}

                  <Tooltip />
                </ComposedChart>
              </ResponsiveContainer>
              <CsvDownloader title={props.title} resultSet={finalData} />
            </>
          );
        }}
      />
    </>
  );
}

export default RepeaterOrders;
