import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Navigator from './Navigator';
import history from './history';

const Index = () => (
  <Router history={history}>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Navigator />
    </QueryParamProvider>
  </Router>
);

render(<Index />, document.getElementById('root'));
