import React from 'react';
import { ExportToCsv } from 'export-to-csv';

function CsvDownloader(props: { title: string; resultSet: any;  }) {
  const download = (event, resultSet) => {
    event.preventDefault();

    const data = resultSet.map((row) => {
      const obj: any = {};
      obj['日付'] = row.x.substr(0, 10);

      for (const key of Object.keys(row)) {
        let n = 0;

        if (key === 'x' || key === 'xValues') {
          continue;
        }

        const value = row[key];
        const title = key.split(',')[0];

        obj[title] = value;
        n += 1;
      }

      return obj;
    });

    const options = {
      fieldSeparator: ',',
      filename: props.title || 'not found',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  };

  return (
    <div style={{ textAlign: 'right' }}>
      <button type="button" onClick={(e) => download(e, props.resultSet)}>
        CSVでダウンロード
      </button>
    </div>
  );
}

export default CsvDownloader;
