import React from 'react';
import ChartConfig from './ChartConfig';
import CurryChart from './CurryChart';

function TotalCharts2(props: {}) {
  return (
    <>
      {ChartConfig.all2().map((chart) => (
        <React.Fragment key={chart.path}>
          <CurryChart title={`${chart.title}`} component={chart.component} options={chart.options} withShops={false} />
        </React.Fragment>
      ))}
    </>
  );
}

export default TotalCharts2;
