import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import { Filter } from '@cubejs-client/core';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';

const cubejsApi = Utils.cubejsApi();

function CumulativeOrders(props: {
  shopIds: string[],
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  options: { [key: string]: any };
  title: string;
  excludeZeroYen: boolean;
  includeUnpaid: boolean;
}) {
  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const orderFilters: Array<Filter> = [
    {
      member: 'Orders.status',
      operator: 'notEquals',
      values: ['canceled'],
    },
  ];

  const customerFilters: Array<Filter> = [];

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      orderFilters.push({
        member: 'Orders.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
      customerFilters.push({
        member: 'Orders.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      orderFilters.push({
        member: 'Orders.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
      customerFilters.push({
        member: 'Orders.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.salesChannels) {
    orderFilters.push({
      member: 'Orders.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
    customerFilters.push({
      member: 'Orders.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
  }

  if (props.salesMethods) {
    const salesMethods: Array<string> = [];
    if (props.salesMethods.includes('app')) {
      salesMethods.push('appOrder');
    }
    if (props.salesMethods.includes('self_checkout')) {
      salesMethods.push('selfCheckoutOrder');
    }
    if (props.salesMethods.includes('qr_bento')) {
      salesMethods.push('qrBento');
    }
    if (salesMethods.length> 0){
      orderFilters.push({
        member: 'Orders.salesMethod',
        operator: 'equals',
        values: salesMethods,
      });
    }
  }

  const segments: Array<string> = [];

  if (props.options && (props.options.excludeZeroYen || props.excludeZeroYen)) {
    segments.push('Orders.excludeZeroYen');
  }

  if (!props.includeUnpaid) {
    segments.push('Orders.paidOrder');
  }

  if (props.options && props.options.onlyApp) {
    segments.push('Orders.appOrder');
  }

  const renderCharts = (resultSet) => {
    const charts: Array<React.ReactNode> = [];
    const series = resultSet.seriesNames();

    if (series.length === 0) {
      return <></>;
    }

    charts.push(
      <Line key={series[0].key} dataKey={series[0].key} name="累計ダウンロード数" stroke="#8BC34A" dot={false} />,
    );

    charts.push(<Line key={series[1].key} dataKey={series[1].key} name="累計カレー数" stroke="#4285F4" dot={false} />);

    charts.push(
      <Line key={series[2].key} dataKey={series[2].key} name="累計カスタマー数" stroke="#DB4437" dot={false} />,
    );

    charts.push(
      <Line key={series[3].key} dataKey={series[3].key} name="累計5回以上カスタマー数" stroke="#ff944d" dot={false} />,
    );

    return charts;
  };

  return (
    <>
      <QueryRenderer
        query={[
          {
            timeDimensions: [
              {
                dimension: 'Customers.createdAt',
                granularity: 'month',
                dateRange: 'from 2019-05-20 to now',
              },
            ],
            measures: ['Customers.cumulativeCustomers'],
            filters: customerFilters,
            dimensions: [],
          },
          {
            timeDimensions: [
              {
                dimension: 'Orders.cookedAt',
                granularity: 'month',
                dateRange: 'from 2019-05-20 to now',
              },
            ],
            measures: [
              'Orders.cumulativeNumberOfCurries',
              'Orders.cumulativeNumberOfUniqueCustomers',
              'Orders.cumulativeNumberOfUniqueRavedCustomers',
            ],
            filters: orderFilters,
            segments,
            dimensions: [],
          },
        ]}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: true })
          return (
            <>
              <ResponsiveContainer width="100%" height={600}>
                <LineChart data={data}>
                  <Legend layout="horizontal" verticalAlign="top" align="center" />
                  <XAxis dataKey="x" tickFormatter={formatXAxis} />
                  <YAxis />
                  <CartesianGrid />

                  {renderCharts(resultSet)}

                  <Tooltip itemSorter={(item) => -(item.value || 0)} />
                </LineChart>
              </ResponsiveContainer>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default CumulativeOrders;
