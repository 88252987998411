import React from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import { Filter } from '@cubejs-client/core';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';
import CustomChart from '../CustomChart';

const md5 = require('md5');

const cubejsApi = Utils.cubejsApi();

function RavedCustomesrsFirstMonth(props: {
  shopIds: string[],
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  chartType: 'bar' | 'line';
  stack: string;
  options: { [key: string]: any };
  title: string;
}) {
  const filters: Array<Filter> = [

  ];

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      filters.push({
        member: 'RavedCustomers.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      filters.push({
        member: 'RavedCustomers.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.salesChannels) {
    filters.push({
      member: 'RavedCustomers.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
  }

  if (props.salesMethods) {
    const salesMethods: Array<string> = [];
    if (props.salesMethods.includes('app')) {
      salesMethods.push('appOrder');
    }
    if (props.salesMethods.includes('self_checkout')) {
      salesMethods.push('selfCheckoutOrder');
    }
    if (props.salesMethods.includes('qr_bento')) {
      salesMethods.push('qrBento');
    }
    if (salesMethods.length> 0){
      filters.push({
        member: 'Orders.salesMethod',
        operator: 'equals',
        values: salesMethods,
      });
    }
  }

  const segments: Array<string> = [];

  const colors = (word) => makeColor(word);

  const makeColor = (word: string) => {
    const seed = parseInt(md5(word.split('').reverse().join('')), 16);
    const random = Math.floor(Math.abs(Math.cos(seed) * 16777215) % 16777215);
    let color = random.toString(16);
    while (color.length < 6) {
      color = `0${color}`;
    }
    return `#${color}`;
  };

  const sortSeries = (a, b) => {
    if (a.yValues[0] > b.yValues[0]) {
      return 1;
    }
    return -1;
  };

  return (
    <>
      <QueryRenderer
        query={{
          measures: ['RavedCustomers.numberOfCustomers'],
          timeDimensions: [
            {
              dimension: 'RavedCustomers.cookedAt',
              granularity: props.period as any,
              dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
            },
          ],
          order: {
            'RavedCustomers.cookedAt': 'asc',
          },
          segments,
          dimensions: ['FirstRavedOrders.firstMonth'],
          filters,
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: false });
          const creaedColors = Object.fromEntries(resultSet.seriesNames().map((series) => [series.yValues[0],  colors(series.yValues[0])]));

          return (
            <>
              <CustomChart colors={creaedColors} data={data} resultSet={resultSet} stack={props.stack} period={props.period} type={props.chartType}/>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default RavedCustomesrsFirstMonth;
