import React from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import 'antd/dist/antd.css';
import { Table } from 'antd';
import { useLocation } from 'react-router-dom';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';

const cubejsApi = Utils.cubejsApi();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ChurnRate(props: {
  shopId: string|null;
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'fyear';
  options: { [key: string]: any };
  title: string;
}) {
  const query = useQuery();

  const yearMonth = query.get('yyyymm') || '';

  const filters: Array<any> = [];

  const renderFunction = (text, record, index) => {
    const percentage = parseFloat(text) * 100;
    return `${percentage.toFixed(2)}%`;
  };

  const renderCharts = (resultSet) => {
    const columnMap: any = {};

    columnMap[`ChurnRate${yearMonth}.shopName`] = {
      title: '店舗',
    };

    columnMap[`ChurnRate${yearMonth}.order`] = {
      title: '表示順',
      hidden: true,
    };

    columnMap[`ChurnRate${yearMonth}.firstVisit`] = {
      title: '初回来店者数',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.churn1`] = {
      title: '2回目',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.drop1`] = {
      title: '離脱率',
      align: 'right',
      render: renderFunction,
    };

    columnMap[`ChurnRate${yearMonth}.churn2`] = {
      title: '3回目',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.drop2`] = {
      title: '離脱率',
      align: 'right',
      render: renderFunction,
    };

    columnMap[`ChurnRate${yearMonth}.churn3`] = {
      title: '4回目',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.drop3`] = {
      title: '離脱率',
      align: 'right',
      render: renderFunction,
    };

    columnMap[`ChurnRate${yearMonth}.churn4`] = {
      title: '5回目',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.drop4`] = {
      title: '離脱率',
      align: 'right',
      render: renderFunction,
    };

    columnMap[`ChurnRate${yearMonth}.churn5`] = {
      title: '6回目',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.drop5`] = {
      title: '離脱率',
      align: 'right',
      render: renderFunction,
    };

    columnMap[`ChurnRate${yearMonth}.churn6`] = {
      title: '7回目',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.drop6`] = {
      title: '離脱率',
      align: 'right',
      render: renderFunction,
    };

    columnMap[`ChurnRate${yearMonth}.churn7`] = {
      title: '8回目',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.drop7`] = {
      title: '離脱率',
      align: 'right',
      render: renderFunction,
    };

    columnMap[`ChurnRate${yearMonth}.churn8`] = {
      title: '9回目',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.drop8`] = {
      title: '離脱率',
      align: 'right',
      render: renderFunction,
    };

    columnMap[`ChurnRate${yearMonth}.churn9`] = {
      title: '10回目',
      align: 'right',
    };

    columnMap[`ChurnRate${yearMonth}.drop9`] = {
      title: '離脱率',
      align: 'right',
      render: renderFunction,
    };

    return (
      <>
        <Table
          pagination={{ pageSize: 500 }}
          columns={resultSet
            .tableColumns()
            .map((col) => ({ ...col, ...columnMap[col.key] }))
            .filter((doc) => !doc.hidden)}
          dataSource={resultSet.tablePivot()}
        />
        <CsvDownloader title={props.title} resultSet={resultSet} />
      </>
    );
  };

  const order: any = {};
  order[`ChurnRate${yearMonth}.order`] = 'asc';

  return (
    <>
      {yearMonth ? (
        <div>
          <h4>
            店舗別離脱率 {yearMonth.substr(0, 4)}/{yearMonth.substr(4, 2)}
          </h4>
        </div>
      ) : (
        ''
      )}

      <QueryRenderer
        query={{
          measures: [
            `ChurnRate${yearMonth}.firstVisit`,
            `ChurnRate${yearMonth}.churn1`,
            `ChurnRate${yearMonth}.drop1`,
            `ChurnRate${yearMonth}.churn2`,
            `ChurnRate${yearMonth}.drop2`,
            `ChurnRate${yearMonth}.churn3`,
            `ChurnRate${yearMonth}.drop3`,
            `ChurnRate${yearMonth}.churn4`,
            `ChurnRate${yearMonth}.drop4`,
            `ChurnRate${yearMonth}.churn5`,
            `ChurnRate${yearMonth}.drop5`,
            `ChurnRate${yearMonth}.churn6`,
            `ChurnRate${yearMonth}.drop6`,
            `ChurnRate${yearMonth}.churn7`,
            `ChurnRate${yearMonth}.drop7`,
            `ChurnRate${yearMonth}.churn8`,
            `ChurnRate${yearMonth}.drop8`,
            `ChurnRate${yearMonth}.churn9`,
            `ChurnRate${yearMonth}.drop9`,
          ],
          timeDimensions: [],
          order,
          dimensions: [`ChurnRate${yearMonth}.shopName`, `ChurnRate${yearMonth}.order`],
          filters,
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          return <>{renderCharts(resultSet)}</>;
        }}
      />
    </>
  );
}

export default ChurnRate;
